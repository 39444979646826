"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.indexRecord = exports.indexArray = exports.indexReadonlyMap = exports.indexReadonlyRecord = exports.indexReadonlyNonEmptyArray = exports.indexReadonlyArray = exports.fromIso = exports.fromAt = exports.index = void 0;
/**
 * **This module is experimental**
 *
 * Experimental features are published in order to get early feedback from the community.
 *
 * A feature tagged as _Experimental_ is in a high state of flux, you're at risk of it changing without notice.
 *
 * @since 2.3.0
 */
var O = require("fp-ts/lib/Option");
var pipeable_1 = require("fp-ts/lib/pipeable");
var _ = require("./internal");
var RM = require("fp-ts/lib/ReadonlyMap");
// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------
/**
 * @category constructors
 * @since 2.3.8
 */
exports.index = _.index;
/**
 * @category constructors
 * @since 2.3.0
 */
var fromAt = function (at) {
    return (0, exports.index)(function (i) { return _.lensComposePrism(_.prismSome())(at.at(i)); });
};
exports.fromAt = fromAt;
/**
 * Lift an instance of `Index` using an `Iso`.
 *
 * @category constructors
 * @since 2.3.0
 */
var fromIso = function (iso) { return function (sia) {
    return (0, exports.index)(function (i) { return (0, pipeable_1.pipe)(iso, _.isoAsOptional, _.optionalComposeOptional(sia.index(i))); });
}; };
exports.fromIso = fromIso;
/**
 * @category constructors
 * @since 2.3.7
 */
exports.indexReadonlyArray = _.indexReadonlyArray;
/**
 * @category constructors
 * @since 2.3.8
 */
exports.indexReadonlyNonEmptyArray = _.indexReadonlyNonEmptyArray;
/**
 * @category constructors
 * @since 2.3.7
 */
exports.indexReadonlyRecord = _.indexReadonlyRecord;
/**
 * @category constructors
 * @since 2.3.7
 */
var indexReadonlyMap = function (E) {
    var lookupE = RM.lookup(E);
    var insertAtE = RM.insertAt(E);
    return function () {
        return (0, exports.index)(function (key) {
            return _.optional(function (s) { return lookupE(key, s); }, function (next) {
                var insert = insertAtE(key, next);
                return function (s) {
                    return (0, pipeable_1.pipe)(lookupE(key, s), O.fold(function () { return s; }, function (prev) { return (next === prev ? s : insert(s)); }));
                };
            });
        });
    };
};
exports.indexReadonlyMap = indexReadonlyMap;
// -------------------------------------------------------------------------------------
// deprecated
// -------------------------------------------------------------------------------------
/**
 * Use `indexReadonlyArray` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
exports.indexArray = _.indexReadonlyArray;
/**
 * Use `indexReadonlyRecord` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
exports.indexRecord = _.indexReadonlyRecord;
