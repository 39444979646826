export var config = {
  background: '#002B36',
  foreground: '#839496',
  selection: '#004454AA',
  selectionMatch: '#005A6FAA',
  cursor: '#D30102',
  dropdownBackground: '#00212B',
  dropdownBorder: '#2AA19899',
  activeLine: '#00cafe11',
  matchingBracket: '#073642',
  keyword: '#859900',
  storage: '#93A1A1',
  variable: '#268BD2',
  parameter: '#268BD2',
  function: '#268BD2',
  string: '#2AA198',
  constant: '#CB4B16',
  type: '#859900',
  class: '#268BD2',
  number: '#D33682',
  comment: '#586E75',
  heading: '#268BD2',
  invalid: '#DC322F',
  regexp: '#DC322F',
  tag: '#268BD2'
};