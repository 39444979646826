"use strict";
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.atReadonlyRecord = exports.at = exports.indexReadonlyRecord = exports.indexReadonlyNonEmptyArray = exports.indexReadonlyArray = exports.index = exports.traversalTraverse = exports.fromTraversable = exports.ApplicativeIdentity = exports.traversalComposeTraversal = exports.traversal = exports.optionalFindFirstNonEmpty = exports.optionalFindFirst = exports.optionalKey = exports.optionalIndexNonEmpty = exports.optionalIndex = exports.optionalComposeOptional = exports.optionalModify = exports.optionalModifyOption = exports.optionalAsTraversal = exports.optional = exports.prismLeft = exports.prismRight = exports.prismSome = exports.prismFromPredicate = exports.prismFromNullable = exports.prismComposeLens = exports.prismSet = exports.prismModify = exports.prismModifyOption = exports.prismAsTraversal = exports.prismAsOptional = exports.prism = exports.lensAtKey = exports.lensComponent = exports.lensProps = exports.lensProp = exports.lensId = exports.lensComposePrism = exports.prismComposePrism = exports.lensComposeLens = exports.lensAsTraversal = exports.lensAsOptional = exports.lens = exports.isoAsTraversal = exports.isoAsOptional = exports.isoAsPrism = exports.isoAsLens = exports.iso = void 0;
var RA = require("fp-ts/lib/ReadonlyArray");
var RR = require("fp-ts/lib/ReadonlyRecord");
var function_1 = require("fp-ts/lib/function");
var O = require("fp-ts/lib/Option");
var E = require("fp-ts/lib/Either");
var pipeable_1 = require("fp-ts/lib/pipeable");
// -------------------------------------------------------------------------------------
// Iso
// -------------------------------------------------------------------------------------
/** @internal */
var iso = function (get, reverseGet) { return ({
    get: get,
    reverseGet: reverseGet
}); };
exports.iso = iso;
/** @internal */
var isoAsLens = function (sa) { return (0, exports.lens)(sa.get, (0, function_1.flow)(sa.reverseGet, function_1.constant)); };
exports.isoAsLens = isoAsLens;
/** @internal */
var isoAsPrism = function (sa) { return (0, exports.prism)((0, function_1.flow)(sa.get, O.some), sa.reverseGet); };
exports.isoAsPrism = isoAsPrism;
/** @internal */
var isoAsOptional = function (sa) {
    return (0, exports.optional)((0, function_1.flow)(sa.get, O.some), (0, function_1.flow)(sa.reverseGet, function_1.constant));
};
exports.isoAsOptional = isoAsOptional;
/** @internal */
var isoAsTraversal = function (sa) {
    return (0, exports.traversal)(function (F) { return function (f) { return function (s) {
        return F.map(f(sa.get(s)), function (a) { return sa.reverseGet(a); });
    }; }; });
};
exports.isoAsTraversal = isoAsTraversal;
// -------------------------------------------------------------------------------------
// Lens
// -------------------------------------------------------------------------------------
/** @internal */
var lens = function (get, set) { return ({ get: get, set: set }); };
exports.lens = lens;
/** @internal */
var lensAsOptional = function (sa) { return (0, exports.optional)((0, function_1.flow)(sa.get, O.some), sa.set); };
exports.lensAsOptional = lensAsOptional;
/** @internal */
var lensAsTraversal = function (sa) {
    return (0, exports.traversal)(function (F) { return function (f) { return function (s) { return F.map(f(sa.get(s)), function (a) { return sa.set(a)(s); }); }; }; });
};
exports.lensAsTraversal = lensAsTraversal;
/** @internal */
var lensComposeLens = function (ab) { return function (sa) {
    return (0, exports.lens)(function (s) { return ab.get(sa.get(s)); }, function (b) { return function (s) { return sa.set(ab.set(b)(sa.get(s)))(s); }; });
}; };
exports.lensComposeLens = lensComposeLens;
/** @internal */
var prismComposePrism = function (ab) { return function (sa) {
    return (0, exports.prism)((0, function_1.flow)(sa.getOption, O.chain(ab.getOption)), (0, function_1.flow)(ab.reverseGet, sa.reverseGet));
}; };
exports.prismComposePrism = prismComposePrism;
/** @internal */
var lensComposePrism = function (ab) { return function (sa) {
    return (0, exports.optionalComposeOptional)((0, exports.prismAsOptional)(ab))((0, exports.lensAsOptional)(sa));
}; };
exports.lensComposePrism = lensComposePrism;
/** @internal */
var lensId = function () { return (0, exports.lens)(function_1.identity, function_1.constant); };
exports.lensId = lensId;
/** @internal */
var lensProp = function (prop) { return function (sa) {
    return (0, exports.lens)(function (s) { return sa.get(s)[prop]; }, function (ap) { return function (s) {
        var _a;
        var oa = sa.get(s);
        if (ap === oa[prop]) {
            return s;
        }
        return sa.set(Object.assign({}, oa, (_a = {}, _a[prop] = ap, _a)))(s);
    }; });
}; };
exports.lensProp = lensProp;
/** @internal */
var lensProps = function () {
    var props = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        props[_i] = arguments[_i];
    }
    return function (sa) {
        return (0, exports.lens)(function (s) {
            var a = sa.get(s);
            var r = {};
            for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
                var k = props_1[_i];
                r[k] = a[k];
            }
            return r;
        }, function (a) { return function (s) {
            var oa = sa.get(s);
            for (var _i = 0, props_2 = props; _i < props_2.length; _i++) {
                var k = props_2[_i];
                if (a[k] !== oa[k]) {
                    return sa.set(Object.assign({}, oa, a))(s);
                }
            }
            return s;
        }; });
    };
};
exports.lensProps = lensProps;
/** @internal */
var lensComponent = function (prop) { return function (sa) {
    return (0, exports.lens)(function (s) { return sa.get(s)[prop]; }, function (ap) { return function (s) {
        var oa = sa.get(s);
        if (ap === oa[prop]) {
            return s;
        }
        var copy = oa.slice();
        copy[prop] = ap;
        return sa.set(copy)(s);
    }; });
}; };
exports.lensComponent = lensComponent;
/** @internal */
var lensAtKey = function (key) { return function (sa) {
    return (0, pipeable_1.pipe)(sa, (0, exports.lensComposeLens)(atReadonlyRecord().at(key)));
}; };
exports.lensAtKey = lensAtKey;
// -------------------------------------------------------------------------------------
// Prism
// -------------------------------------------------------------------------------------
/** @internal */
var prism = function (getOption, reverseGet) { return ({ getOption: getOption, reverseGet: reverseGet }); };
exports.prism = prism;
/** @internal */
var prismAsOptional = function (sa) { return (0, exports.optional)(sa.getOption, function (a) { return (0, exports.prismSet)(a)(sa); }); };
exports.prismAsOptional = prismAsOptional;
/** @internal */
var prismAsTraversal = function (sa) {
    return (0, exports.traversal)(function (F) { return function (f) { return function (s) {
        return (0, pipeable_1.pipe)(sa.getOption(s), O.fold(function () { return F.of(s); }, function (a) { return F.map(f(a), function (a) { return (0, exports.prismSet)(a)(sa)(s); }); }));
    }; }; });
};
exports.prismAsTraversal = prismAsTraversal;
/** @internal */
var prismModifyOption = function (f) { return function (sa) { return function (s) {
    return (0, pipeable_1.pipe)(sa.getOption(s), O.map(function (o) {
        var n = f(o);
        return n === o ? s : sa.reverseGet(n);
    }));
}; }; };
exports.prismModifyOption = prismModifyOption;
/** @internal */
var prismModify = function (f) { return function (sa) {
    var g = (0, exports.prismModifyOption)(f)(sa);
    return function (s) {
        return (0, pipeable_1.pipe)(g(s), O.getOrElse(function () { return s; }));
    };
}; };
exports.prismModify = prismModify;
/** @internal */
var prismSet = function (a) { return (0, exports.prismModify)(function () { return a; }); };
exports.prismSet = prismSet;
/** @internal */
var prismComposeLens = function (ab) { return function (sa) {
    return (0, exports.optionalComposeOptional)((0, exports.lensAsOptional)(ab))((0, exports.prismAsOptional)(sa));
}; };
exports.prismComposeLens = prismComposeLens;
/** @internal */
var prismFromNullable = function () { return (0, exports.prism)(O.fromNullable, function_1.identity); };
exports.prismFromNullable = prismFromNullable;
/** @internal */
var prismFromPredicate = function (predicate) {
    return (0, exports.prism)(O.fromPredicate(predicate), function_1.identity);
};
exports.prismFromPredicate = prismFromPredicate;
/** @internal */
var prismSome = function () { return (0, exports.prism)(function_1.identity, O.some); };
exports.prismSome = prismSome;
/** @internal */
var prismRight = function () { return (0, exports.prism)(O.fromEither, E.right); };
exports.prismRight = prismRight;
/** @internal */
var prismLeft = function () {
    return (0, exports.prism)(function (s) { return (E.isLeft(s) ? O.some(s.left) : O.none); }, // TODO: replace with E.getLeft in v3
    E.left);
};
exports.prismLeft = prismLeft;
// -------------------------------------------------------------------------------------
// Optional
// -------------------------------------------------------------------------------------
/** @internal */
var optional = function (getOption, set) { return ({
    getOption: getOption,
    set: set
}); };
exports.optional = optional;
/** @internal */
var optionalAsTraversal = function (sa) {
    return (0, exports.traversal)(function (F) { return function (f) { return function (s) {
        return (0, pipeable_1.pipe)(sa.getOption(s), O.fold(function () { return F.of(s); }, function (a) { return F.map(f(a), function (a) { return sa.set(a)(s); }); }));
    }; }; });
};
exports.optionalAsTraversal = optionalAsTraversal;
/** @internal */
var optionalModifyOption = function (f) { return function (optional) { return function (s) {
    return (0, pipeable_1.pipe)(optional.getOption(s), O.map(function (a) {
        var n = f(a);
        return n === a ? s : optional.set(n)(s);
    }));
}; }; };
exports.optionalModifyOption = optionalModifyOption;
/** @internal */
var optionalModify = function (f) { return function (optional) {
    var g = (0, exports.optionalModifyOption)(f)(optional);
    return function (s) {
        return (0, pipeable_1.pipe)(g(s), O.getOrElse(function () { return s; }));
    };
}; };
exports.optionalModify = optionalModify;
/** @internal */
var optionalComposeOptional = function (ab) { return function (sa) {
    return (0, exports.optional)((0, function_1.flow)(sa.getOption, O.chain(ab.getOption)), function (b) { return (0, exports.optionalModify)(ab.set(b))(sa); });
}; };
exports.optionalComposeOptional = optionalComposeOptional;
/** @internal */
var optionalIndex = function (i) { return function (sa) {
    return (0, pipeable_1.pipe)(sa, (0, exports.optionalComposeOptional)((0, exports.indexReadonlyArray)().index(i)));
}; };
exports.optionalIndex = optionalIndex;
/** @internal */
var optionalIndexNonEmpty = function (i) { return function (sa) { return (0, pipeable_1.pipe)(sa, (0, exports.optionalComposeOptional)((0, exports.indexReadonlyNonEmptyArray)().index(i))); }; };
exports.optionalIndexNonEmpty = optionalIndexNonEmpty;
/** @internal */
var optionalKey = function (key) { return function (sa) {
    return (0, pipeable_1.pipe)(sa, (0, exports.optionalComposeOptional)((0, exports.indexReadonlyRecord)().index(key)));
}; };
exports.optionalKey = optionalKey;
/** @internal */
var optionalFindFirst = function (predicate) {
    return (0, exports.optional)(RA.findFirst(predicate), function (a) { return function (s) {
        return (0, pipeable_1.pipe)(RA.findIndex(predicate)(s), O.fold(function () { return s; }, function (i) { return RA.unsafeUpdateAt(i, a, s); }));
    }; });
};
exports.optionalFindFirst = optionalFindFirst;
var unsafeUpdateAt = function (i, a, as) {
    if (as[i] === a) {
        return as;
    }
    else {
        var xs = __spreadArray([as[0]], as.slice(1), true);
        xs[i] = a;
        return xs;
    }
};
/** @internal */
var optionalFindFirstNonEmpty = function (predicate) {
    return (0, exports.optional)(RA.findFirst(predicate), function (a) { return function (as) {
        return (0, pipeable_1.pipe)(RA.findIndex(predicate)(as), O.fold(function () { return as; }, function (i) { return unsafeUpdateAt(i, a, as); }));
    }; });
};
exports.optionalFindFirstNonEmpty = optionalFindFirstNonEmpty;
// -------------------------------------------------------------------------------------
// Traversal
// -------------------------------------------------------------------------------------
/** @internal */
var traversal = function (modifyF) { return ({
    modifyF: modifyF
}); };
exports.traversal = traversal;
/** @internal */
function traversalComposeTraversal(ab) {
    return function (sa) { return (0, exports.traversal)(function (F) { return function (f) { return sa.modifyF(F)(ab.modifyF(F)(f)); }; }); };
}
exports.traversalComposeTraversal = traversalComposeTraversal;
/** @internal */
exports.ApplicativeIdentity = {
    URI: 'Identity',
    map: function (fa, f) { return f(fa); },
    of: function_1.identity,
    ap: 
    /* istanbul ignore next */
    function (fab, fa) { return fab(fa); }
};
var isIdentity = function (F) { return F.URI === 'Identity'; };
function fromTraversable(T) {
    return function () {
        return (0, exports.traversal)(function (F) {
            // if `F` is `Identity` then `traverseF = map`
            var traverseF = isIdentity(F)
                ? T.map
                : T.traverse(F);
            return function (f) { return function (s) { return traverseF(s, f); }; };
        });
    };
}
exports.fromTraversable = fromTraversable;
/** @internal */
function traversalTraverse(T) {
    return traversalComposeTraversal(fromTraversable(T)());
}
exports.traversalTraverse = traversalTraverse;
// -------------------------------------------------------------------------------------
// Ix
// -------------------------------------------------------------------------------------
/** @internal */
var index = function (index) { return ({ index: index }); };
exports.index = index;
/** @internal */
var indexReadonlyArray = function () {
    return (0, exports.index)(function (i) {
        return (0, exports.optional)(function (as) { return RA.lookup(i, as); }, function (a) { return function (as) {
            return (0, pipeable_1.pipe)(RA.lookup(i, as), O.fold(function () { return as; }, function () { return RA.unsafeUpdateAt(i, a, as); }));
        }; });
    });
};
exports.indexReadonlyArray = indexReadonlyArray;
/** @internal */
var indexReadonlyNonEmptyArray = function () {
    return (0, exports.index)(function (i) {
        return (0, exports.optional)(function (as) { return RA.lookup(i, as); }, function (a) { return function (as) {
            return (0, pipeable_1.pipe)(RA.lookup(i, as), O.fold(function () { return as; }, function () { return unsafeUpdateAt(i, a, as); }));
        }; });
    });
};
exports.indexReadonlyNonEmptyArray = indexReadonlyNonEmptyArray;
/** @internal */
var indexReadonlyRecord = function () {
    return (0, exports.index)(function (k) {
        return (0, exports.optional)(function (r) { return RR.lookup(k, r); }, function (a) { return function (r) {
            if (r[k] === a || O.isNone(RR.lookup(k, r))) {
                return r;
            }
            return RR.insertAt(k, a)(r);
        }; });
    });
};
exports.indexReadonlyRecord = indexReadonlyRecord;
// -------------------------------------------------------------------------------------
// At
// -------------------------------------------------------------------------------------
/** @internal */
var at = function (at) { return ({ at: at }); };
exports.at = at;
/** @internal */
function atReadonlyRecord() {
    return (0, exports.at)(function (key) {
        return (0, exports.lens)(function (r) { return RR.lookup(key, r); }, O.fold(function () { return RR.deleteAt(key); }, function (a) { return RR.insertAt(key, a); }));
    });
}
exports.atReadonlyRecord = atReadonlyRecord;
