export var config = {
  background: '#FDF6E3',
  foreground: '#657B83',
  selection: '#EEE8D5',
  selectionMatch: '#EEE8D5',
  cursor: '#657B83',
  dropdownBackground: '#EEE8D5',
  dropdownBorder: '#D3AF86',
  activeLine: '#3d392d11',
  matchingBracket: '#EEE8D5',
  keyword: '#859900',
  storage: '#586E75',
  variable: '#268BD2',
  parameter: '#268BD2',
  function: '#268BD2',
  string: '#2AA198',
  constant: '#CB4B16',
  type: '#859900',
  class: '#268BD2',
  number: '#D33682',
  comment: '#93A1A1',
  heading: '#268BD2',
  invalid: '#DC322F',
  regexp: '#DC322F',
  tag: '#268BD2'
};