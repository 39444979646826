/**
 * @since 1.2.0
 */
import { Index, Optional } from '..';
import { lookup, updateAt } from 'fp-ts/es6/Array';
import { isNone } from 'fp-ts/es6/Option';
/**
 * @category constructor
 * @since 1.2.0
 */
export function indexArray() {
    return new Index(function (i) {
        return new Optional(function (as) { return lookup(i, as); }, function (a) { return function (as) {
            var oas = updateAt(i, a)(as);
            if (isNone(oas)) {
                return as;
            }
            else {
                return oas.value;
            }
        }; });
    });
}
