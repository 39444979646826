import { languages } from '@codemirror/language-data';
import { StreamLanguage } from '@codemirror/language';
import { markdown as _markdown, markdownLanguage } from '@codemirror/lang-markdown';
import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { css } from '@codemirror/lang-css';
import { less as _less } from '@codemirror/lang-less';
import { sass as _sass } from '@codemirror/lang-sass';
import { json } from '@codemirror/lang-json';
import { mermaid as _mermaid } from 'codemirror-lang-mermaid';
import { python } from '@codemirror/lang-python';
import { xml } from '@codemirror/lang-xml';
import { sql, MySQL, PostgreSQL } from '@codemirror/lang-sql';
import { java } from '@codemirror/lang-java';
import { rust } from '@codemirror/lang-rust';
import { cpp } from '@codemirror/lang-cpp';
import { lezer } from '@codemirror/lang-lezer';
import { php } from '@codemirror/lang-php';
import { liquid as _liquid } from '@codemirror/lang-liquid';
import { wast } from '@codemirror/lang-wast';
import { vue as _vue } from '@codemirror/lang-vue';
import { angular as _angular } from '@codemirror/lang-angular';
import { nix as _nix } from '@replit/codemirror-lang-nix';
import { svelte as _svelte } from '@replit/codemirror-lang-svelte';
import { csharp as _csharp } from '@replit/codemirror-lang-csharp';
import { solidity as _solidity } from '@replit/codemirror-lang-solidity';
import { apl as _apl } from '@codemirror/legacy-modes/mode/apl';
import { asciiArmor as _asciiArmor } from '@codemirror/legacy-modes/mode/asciiarmor';
// import { asn1 } from '@codemirror/legacy-modes/mode/asn1';
import { asterisk as _asterisk } from '@codemirror/legacy-modes/mode/asterisk';
import { brainfuck as _brainfuck } from '@codemirror/legacy-modes/mode/brainfuck';
import { c as _c, scala as _scala, kotlin as _kotlin, shader as _shader, nesC as _nesC, objectiveC as _objectiveC, objectiveCpp as _objectiveCpp, squirrel as _squirrel, ceylon as _ceylon, dart as _dart } from '@codemirror/legacy-modes/mode/clike';
import { clojure as _clojure } from '@nextjournal/lang-clojure';
import { cmake as _cmake } from '@codemirror/legacy-modes/mode/cmake';
import { cobol as _cobol } from '@codemirror/legacy-modes/mode/cobol';
import { coffeeScript } from '@codemirror/legacy-modes/mode/coffeescript';
import { commonLisp as _commonLisp } from '@codemirror/legacy-modes/mode/commonlisp';
import { crystal as _crystal } from '@codemirror/legacy-modes/mode/crystal';
import { cypher as _cypher } from '@codemirror/legacy-modes/mode/cypher';
import { d as _d } from '@codemirror/legacy-modes/mode/d';
import { diff as _diff } from '@codemirror/legacy-modes/mode/diff';
import { dockerFile } from '@codemirror/legacy-modes/mode/dockerfile';
import { dtd as _dtd } from '@codemirror/legacy-modes/mode/dtd';
import { dylan as _dylan } from '@codemirror/legacy-modes/mode/dylan';
import { ebnf as _ebnf } from '@codemirror/legacy-modes/mode/ebnf';
import { ecl as _ecl } from '@codemirror/legacy-modes/mode/ecl';
import { eiffel as _eiffel } from '@codemirror/legacy-modes/mode/eiffel';
import { elm as _elm } from '@codemirror/legacy-modes/mode/elm';
import { erlang as _erlang } from '@codemirror/legacy-modes/mode/erlang';
import { factor as _factor } from '@codemirror/legacy-modes/mode/factor';
import { fcl as _fcl } from '@codemirror/legacy-modes/mode/fcl';
import { forth as _forth } from '@codemirror/legacy-modes/mode/forth';
import { fortran as _fortran } from '@codemirror/legacy-modes/mode/fortran';
import { gas as _gas } from '@codemirror/legacy-modes/mode/gas';
import { gherkin as _gherkin } from '@codemirror/legacy-modes/mode/gherkin';
import { go as _go } from '@codemirror/legacy-modes/mode/go';
import { groovy as _groovy } from '@codemirror/legacy-modes/mode/groovy';
import { haskell as _haskell } from '@codemirror/legacy-modes/mode/haskell';
import { haxe as _haxe } from '@codemirror/legacy-modes/mode/haxe';
import { http as _http } from '@codemirror/legacy-modes/mode/http';
import { idl as _idl } from '@codemirror/legacy-modes/mode/idl';
import { jinja2 as _jinja } from '@codemirror/legacy-modes/mode/jinja2';
import { julia as _julia } from '@codemirror/legacy-modes/mode/julia';
import { liveScript } from '@codemirror/legacy-modes/mode/livescript';
import { lua as _lua } from '@codemirror/legacy-modes/mode/lua';
import { mathematica as _mathematica } from '@codemirror/legacy-modes/mode/mathematica';
import { mbox as _mbox } from '@codemirror/legacy-modes/mode/mbox';
import { mirc as _mirc } from '@codemirror/legacy-modes/mode/mirc';
// import { mllike } from '@codemirror/legacy-modes/mode/mllike';
import { modelica as _modelica } from '@codemirror/legacy-modes/mode/modelica';
import { mscgen as _mscgen } from '@codemirror/legacy-modes/mode/mscgen';
import { mumps as _mumps } from '@codemirror/legacy-modes/mode/mumps';
import { nginx as _nginx } from '@codemirror/legacy-modes/mode/nginx';
import { nsis as _nsis } from '@codemirror/legacy-modes/mode/nsis';
import { ntriples as _ntriples } from '@codemirror/legacy-modes/mode/ntriples';
import { octave as _octave } from '@codemirror/legacy-modes/mode/octave';
import { oz as _oz } from '@codemirror/legacy-modes/mode/oz';
import { pascal as _pascal } from '@codemirror/legacy-modes/mode/pascal';
import { perl as _perl } from '@codemirror/legacy-modes/mode/perl';
import { pig as _pig } from '@codemirror/legacy-modes/mode/pig';
import { powerShell } from '@codemirror/legacy-modes/mode/powershell';
import { properties as _properties } from '@codemirror/legacy-modes/mode/properties';
import { protobuf as _protobuf } from '@codemirror/legacy-modes/mode/protobuf';
import { puppet as _puppet } from '@codemirror/legacy-modes/mode/puppet';
import { q as _q } from '@codemirror/legacy-modes/mode/q';
import { r as _r } from '@codemirror/legacy-modes/mode/r';
// import { rpm } from '@codemirror/legacy-modes/mode/rpm';
import { ruby as _ruby } from '@codemirror/legacy-modes/mode/ruby';
import { sas as _sas } from '@codemirror/legacy-modes/mode/sas';
import { scheme as _scheme } from '@codemirror/legacy-modes/mode/scheme';
import { shell as _shell } from '@codemirror/legacy-modes/mode/shell';
import { sieve as _sieve } from '@codemirror/legacy-modes/mode/sieve';
// import { mode } from '@codemirror/legacy-modes/mode/simple-mode';
import { smalltalk as _smalltalk } from '@codemirror/legacy-modes/mode/smalltalk';
import { solr as _solr } from '@codemirror/legacy-modes/mode/solr';
import { sparql as _sparql } from '@codemirror/legacy-modes/mode/sparql';
import { spreadsheet as _spreadsheet } from '@codemirror/legacy-modes/mode/spreadsheet';
import { stex as _stex } from '@codemirror/legacy-modes/mode/stex';
import { stylus as _stylus } from '@codemirror/legacy-modes/mode/stylus';
import { swift as _swift } from '@codemirror/legacy-modes/mode/swift';
import { tcl as _tcl } from '@codemirror/legacy-modes/mode/tcl';
import { textile as _textile } from '@codemirror/legacy-modes/mode/textile';
import { tiddlyWiki as _tiddlyWiki } from '@codemirror/legacy-modes/mode/tiddlywiki';
import { tiki as _tiki } from '@codemirror/legacy-modes/mode/tiki';
import { toml as _toml } from '@codemirror/legacy-modes/mode/toml';
import { troff as _troff } from '@codemirror/legacy-modes/mode/troff';
// import { cfg } from '@codemirror/legacy-modes/mode/ttcn-cfg';
import { ttcn as _ttcn } from '@codemirror/legacy-modes/mode/ttcn';
import { turtle as _turtle } from '@codemirror/legacy-modes/mode/turtle';
import { vb as _vb } from '@codemirror/legacy-modes/mode/vb';
import { vbScript } from '@codemirror/legacy-modes/mode/vbscript';
import { velocity as _velocity } from '@codemirror/legacy-modes/mode/velocity';
import { verilog as _verilog } from '@codemirror/legacy-modes/mode/verilog';
import { vhdl as _vhdl } from '@codemirror/legacy-modes/mode/vhdl';
import { webIDL as _webIDL } from '@codemirror/legacy-modes/mode/webidl';
import { xQuery as _xQuery } from '@codemirror/legacy-modes/mode/xquery';
import { yacas as _yacas } from '@codemirror/legacy-modes/mode/yacas';
import { yaml as _yaml } from '@codemirror/legacy-modes/mode/yaml';
import { z80 as _z } from '@codemirror/legacy-modes/mode/z80';
export var langs = {
  apl: () => StreamLanguage.define(_apl),
  asciiArmor: () => StreamLanguage.define(_asciiArmor),
  // asn1: () => StreamLanguage.define(asn1),
  asterisk: () => StreamLanguage.define(_asterisk),
  // clike: () => StreamLanguage.define(clike),
  c: () => StreamLanguage.define(_c),
  csharp: () => _csharp(),
  scala: () => StreamLanguage.define(_scala),
  solidity: () => _solidity,
  kotlin: () => StreamLanguage.define(_kotlin),
  shader: () => StreamLanguage.define(_shader),
  nesC: () => StreamLanguage.define(_nesC),
  objectiveC: () => StreamLanguage.define(_objectiveC),
  objectiveCpp: () => StreamLanguage.define(_objectiveCpp),
  squirrel: () => StreamLanguage.define(_squirrel),
  ceylon: () => StreamLanguage.define(_ceylon),
  dart: () => StreamLanguage.define(_dart),
  cmake: () => StreamLanguage.define(_cmake),
  cobol: () => StreamLanguage.define(_cobol),
  commonLisp: () => StreamLanguage.define(_commonLisp),
  crystal: () => StreamLanguage.define(_crystal),
  cypher: () => StreamLanguage.define(_cypher),
  d: () => StreamLanguage.define(_d),
  diff: () => StreamLanguage.define(_diff),
  dtd: () => StreamLanguage.define(_dtd),
  dylan: () => StreamLanguage.define(_dylan),
  ebnf: () => StreamLanguage.define(_ebnf),
  ecl: () => StreamLanguage.define(_ecl),
  eiffel: () => StreamLanguage.define(_eiffel),
  elm: () => StreamLanguage.define(_elm),
  factor: () => StreamLanguage.define(_factor),
  fcl: () => StreamLanguage.define(_fcl),
  forth: () => StreamLanguage.define(_forth),
  fortran: () => StreamLanguage.define(_fortran),
  gas: () => StreamLanguage.define(_gas),
  gherkin: () => StreamLanguage.define(_gherkin),
  groovy: () => StreamLanguage.define(_groovy),
  haskell: () => StreamLanguage.define(_haskell),
  haxe: () => StreamLanguage.define(_haxe),
  http: () => StreamLanguage.define(_http),
  idl: () => StreamLanguage.define(_idl),
  jinja2: () => StreamLanguage.define(_jinja),
  mathematica: () => StreamLanguage.define(_mathematica),
  mbox: () => StreamLanguage.define(_mbox),
  mirc: () => StreamLanguage.define(_mirc),
  modelica: () => StreamLanguage.define(_modelica),
  mscgen: () => StreamLanguage.define(_mscgen),
  mumps: () => StreamLanguage.define(_mumps),
  nsis: () => StreamLanguage.define(_nsis),
  ntriples: () => StreamLanguage.define(_ntriples),
  octave: () => StreamLanguage.define(_octave),
  oz: () => StreamLanguage.define(_oz),
  pig: () => StreamLanguage.define(_pig),
  properties: () => StreamLanguage.define(_properties),
  protobuf: () => StreamLanguage.define(_protobuf),
  puppet: () => StreamLanguage.define(_puppet),
  q: () => StreamLanguage.define(_q),
  sas: () => StreamLanguage.define(_sas),
  sass: () => _sass(),
  liquid: () => _liquid(),
  mermaid: () => _mermaid(),
  nix: () => _nix(),
  svelte: () => _svelte(),
  sieve: () => StreamLanguage.define(_sieve),
  smalltalk: () => StreamLanguage.define(_smalltalk),
  solr: () => StreamLanguage.define(_solr),
  sparql: () => StreamLanguage.define(_sparql),
  spreadsheet: () => StreamLanguage.define(_spreadsheet),
  stex: () => StreamLanguage.define(_stex),
  textile: () => StreamLanguage.define(_textile),
  tiddlyWiki: () => StreamLanguage.define(_tiddlyWiki),
  tiki: () => StreamLanguage.define(_tiki),
  troff: () => StreamLanguage.define(_troff),
  ttcn: () => StreamLanguage.define(_ttcn),
  turtle: () => StreamLanguage.define(_turtle),
  velocity: () => StreamLanguage.define(_velocity),
  verilog: () => StreamLanguage.define(_verilog),
  vhdl: () => StreamLanguage.define(_vhdl),
  webIDL: () => StreamLanguage.define(_webIDL),
  xQuery: () => StreamLanguage.define(_xQuery),
  yacas: () => StreamLanguage.define(_yacas),
  z80: () => StreamLanguage.define(_z),
  wast,
  javascript,
  jsx: () => javascript({
    jsx: true
  }),
  typescript: () => javascript({
    typescript: true
  }),
  tsx: () => javascript({
    jsx: true,
    typescript: true
  }),
  vue: () => _vue(),
  angular: () => _angular(),
  json,
  html,
  css,
  python,
  markdown: () => _markdown({
    base: markdownLanguage,
    codeLanguages: languages
  }),
  xml,
  sql,
  mysql: () => sql({
    dialect: MySQL
  }),
  pgsql: () => sql({
    dialect: PostgreSQL
  }),
  java,
  rust,
  cpp,
  // clike: () => StreamLanguage.define(clike),
  // clike: () => clike({ }),
  lezer,
  php,
  go: () => StreamLanguage.define(_go),
  shell: () => StreamLanguage.define(_shell),
  lua: () => StreamLanguage.define(_lua),
  swift: () => StreamLanguage.define(_swift),
  tcl: () => StreamLanguage.define(_tcl),
  yaml: () => StreamLanguage.define(_yaml),
  vb: () => StreamLanguage.define(_vb),
  powershell: () => StreamLanguage.define(powerShell),
  brainfuck: () => StreamLanguage.define(_brainfuck),
  stylus: () => StreamLanguage.define(_stylus),
  erlang: () => StreamLanguage.define(_erlang),
  nginx: () => StreamLanguage.define(_nginx),
  perl: () => StreamLanguage.define(_perl),
  ruby: () => StreamLanguage.define(_ruby),
  pascal: () => StreamLanguage.define(_pascal),
  livescript: () => StreamLanguage.define(liveScript),
  less: () => _less(),
  scheme: () => StreamLanguage.define(_scheme),
  toml: () => StreamLanguage.define(_toml),
  vbscript: () => StreamLanguage.define(vbScript),
  clojure: () => _clojure(),
  coffeescript: () => StreamLanguage.define(coffeeScript),
  julia: () => StreamLanguage.define(_julia),
  dockerfile: () => StreamLanguage.define(dockerFile),
  r: () => StreamLanguage.define(_r)
};

/** Language list */
export var langNames = Object.keys(langs);
export function loadLanguage(name) {
  return langs[name] ? langs[name]() : null;
}