"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.indexArray = void 0;
/**
 * @since 1.2.0
 */
var __1 = require("..");
var Array_1 = require("fp-ts/lib/Array");
var Option_1 = require("fp-ts/lib/Option");
/**
 * @category constructor
 * @since 1.2.0
 */
function indexArray() {
    return new __1.Index(function (i) {
        return new __1.Optional(function (as) { return (0, Array_1.lookup)(i, as); }, function (a) { return function (as) {
            var oas = (0, Array_1.updateAt)(i, a)(as);
            if ((0, Option_1.isNone)(oas)) {
                return as;
            }
            else {
                return oas.value;
            }
        }; });
    });
}
exports.indexArray = indexArray;
