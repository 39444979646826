"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.atRecord = exports.atReadonlySet = exports.atReadonlyMap = exports.atReadonlyRecord = exports.fromIso = exports.at = void 0;
var O = require("fp-ts/lib/Option");
var pipeable_1 = require("fp-ts/lib/pipeable");
var RM = require("fp-ts/lib/ReadonlyMap");
var RS = require("fp-ts/lib/ReadonlySet");
var _ = require("./internal");
// -------------------------------------------------------------------------------------
// constructors
// -------------------------------------------------------------------------------------
/**
 * @category constructors
 * @since 2.3.8
 */
exports.at = _.at;
/**
 * Lift an instance of `At` using an `Iso`.
 *
 * @category constructors
 * @since 2.3.0
 */
var fromIso = function (iso) { return function (sia) {
    return (0, exports.at)(function (i) { return (0, pipeable_1.pipe)(iso, _.isoAsLens, _.lensComposeLens(sia.at(i))); });
}; };
exports.fromIso = fromIso;
/**
 * @category constructors
 * @since 2.3.7
 */
exports.atReadonlyRecord = _.atReadonlyRecord;
/**
 * @category constructors
 * @since 2.3.7
 */
var atReadonlyMap = function (E) {
    var lookupE = RM.lookup(E);
    var deleteAtE = RM.deleteAt(E);
    var insertAtE = RM.insertAt(E);
    return function () {
        return (0, exports.at)(function (key) {
            return _.lens(function (s) { return lookupE(key, s); }, O.fold(function () { return deleteAtE(key); }, function (a) { return insertAtE(key, a); }));
        });
    };
};
exports.atReadonlyMap = atReadonlyMap;
/**
 * @category constructors
 * @since 2.3.7
 */
var atReadonlySet = function (E) {
    var elemE = RS.elem(E);
    var insertE = RS.insert(E);
    var removeE = RS.remove(E);
    return (0, exports.at)(function (a) {
        var insert = insertE(a);
        var remove = removeE(a);
        return _.lens(function (s) { return elemE(a, s); }, function (b) { return function (s) { return (b ? insert(s) : remove(s)); }; });
    });
};
exports.atReadonlySet = atReadonlySet;
// -------------------------------------------------------------------------------------
// deprecated
// -------------------------------------------------------------------------------------
/**
 * Use `atReadonlyRecord` instead.
 *
 * @category constructors
 * @since 2.3.2
 * @deprecated
 */
exports.atRecord = _.atReadonlyRecord;
